/**
 * External dependencies
 */
import PropTypes from 'prop-types';

/**
 * External dependencies.
 */
import { useState } from '@wordpress/element';
import { addQueryArgs } from '@wordpress/url';
import apiFetch from '@wordpress/api-fetch';
import { pickBy, identity, get, omit, merge, has } from 'lodash';
import { localizeDate, CONVERT_DIRECTIONS, addTimeIfToday } from '../../includes/date-utils';
import { DEFAULT_PAGE_SIZE } from '../../includes/misc-utils';

/**
 * Internal dependencies.
 */
import AvailabilityContext from './context';

/**
 * Returns a string representation of a sorted query object.
 *
 * @param {Object} query Current state
 * @return {String}       Query Key
 */
const getJsonString = ( query = {} ) => {
	return JSON.stringify( query, Object.keys( query ).sort() );
};

const DEFAULT_PAGE = "default";

/**
 * Returns a string representation of the availability query argument.
 *
 * @param {Object} query Current state
 * @return {String}       Query Key
 */
const serializeQueryArgs = ( query = {} ) => {
	return getJsonString( omit( query, [ 'page' ] ) );
};

/*
 * Generate list of days in query range with unavailable days removed.
 */
const processAvailability = ( availabilityData ) => {
	const mapper = ( src ) => {
		src.productId = src.product_id;
		src.durationUnit = src.duration_unit;
		delete src.product_id;
		delete src.duration_unit;
		src.serverDate = src.date;
		src.date = localizeDate( src.date, CONVERT_DIRECTIONS.SERVER_TO_LOCAL );
		return src;
	};

	return availabilityData.map( mapper );
};

const AvailabilityState = ( props ) => {
	const [ availability, setAvailability ] = useState( [] );
	const [ availabilityRequesting, setAvailabilityRequesting ] = useState( false );
	const [ availabilityError, setAvailabilityError ] = useState( false );

	const requestAvailability = ( query ) => {
		const {
			minDate,
			maxDate,
			page,
			productIds,
			categoryIds,
			resourceIds,
			getPastTimes,
			pageSize = DEFAULT_PAGE_SIZE,
			showSoldOut,
		} = query;

		const queryArgs = {
			product_ids: productIds,
			category_ids: categoryIds,
			resource_ids: resourceIds,
			min_date: minDate ? encodeURIComponent( localizeDate( addTimeIfToday( minDate ), CONVERT_DIRECTIONS.LOCAL_TO_SERVER ) ) : minDate,
			max_date: maxDate ? encodeURIComponent( localizeDate( maxDate, CONVERT_DIRECTIONS.LOCAL_TO_SERVER ) ) : maxDate,
			page: page,
			limit: pageSize,
			get_past_times: ( getPastTimes === false ) ? false : true,
			hide_unavailable: ! showSoldOut,
		};

		const path = addQueryArgs(
			'/wc-bookings/v1/products/slots',
			pickBy( queryArgs, identity )
		);

		const storeAvailability = ( requestedAvailability, count, _query ) => {
			if ( requestAvailability.length === 0 ) {
				return;
			}

			const queryKey = serializeQueryArgs( _query );
			const newPage = _query.page === undefined ? DEFAULT_PAGE : _query.page;

			// Copy availability to update object reference.
			const newAvailability = merge( {}, availability );

			// Check if we have the key. Add placeholder if not.
			if ( ! has( newAvailability, queryKey ) ) {
				newAvailability[ queryKey ] = {
					count,
					pages: {},
				};
			}

			// Add new page.
			newAvailability[ queryKey ].pages[ newPage ] = requestedAvailability;

			// Store updated availability ( old + new ).
			setAvailability( newAvailability );
		};

		setAvailabilityRequesting( true );
		apiFetch( { path } ).then(
			( { records, count } ) => {
				const newAvailability = processAvailability( records );
				storeAvailability( newAvailability, count, query );
				setAvailabilityError( false );
				setAvailabilityRequesting( false );
			},
			( error ) => {
				setAvailabilityError( error );
				setAvailabilityRequesting( false );
			}
		);
	};

	const getAvailability = ( query ) => {
		const data = get( availability, [ serializeQueryArgs( query ) ], false );
		if ( data === false ) {
			return [];
		}

		const records = [];
		const pages = Object.keys( data.pages ).sort();

		// Merge pages.
		pages.map( ( page ) => {
			records.push( ...data.pages[ page ] );
		} );

		return records;
	};

	const getAvailabilityTotalRecords = ( query ) => {
		return get( availability, [ serializeQueryArgs( query ), 'count' ], 0 );
	};

	const getRemainingRecordsCount = ( query ) => {
		const recordsCount = getAvailability( query ).length;
		const totalRecordsCount = getAvailabilityTotalRecords( query );
		return totalRecordsCount - recordsCount;
	};

	const getAvailabilityCount = ( query ) => {
		return getAvailability( query ).length;
	};

	const hasResultsForQuery = ( query ) => {
		const page = query.page === undefined ? DEFAULT_PAGE : query.page ;
		return get( availability, [ serializeQueryArgs( query ), 'pages', page ], false ) === false ? false : true;
	};

	const highestPageForQuery = ( query ) => {
		const data = get( availability, [ serializeQueryArgs( query ) ], false );

		if ( data === false ) {
			return 1;
		}

		return Math.max( ...Object.keys( data.pages ) );
	};

	return (
		<AvailabilityContext.Provider value={ {
			hasResultsForQuery,
			availabilityRequesting,
			getAvailability,
			getAvailabilityCount,
			getRemainingRecordsCount,
			requestAvailability,
			availabilityError,
			highestPageForQuery,
		} }
		>
			{ props.children }
		</AvailabilityContext.Provider>
	);
};

AvailabilityState.propTypes = {
	children: PropTypes.object,
};

export default AvailabilityState;
