/**
 * External dependencies
 */
import { date as formatTime } from '@wordpress/date';
import { useContext, useState, useEffect } from '@wordpress/element';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { filter, mapValues, transform } from 'lodash';
import { __ } from '@wordpress/i18n';
import classNames from 'classnames';
import { withViewportMatch } from '@wordpress/viewport';
import memorize from 'memorize-one';

/**
 * Internal dependencies
 */
import EventCalendarItem from '../../components/event-calendar-item';
import { getDayOfWeek, getCalStartEndTime, formatDateQueryLimits, DATE_RANGES, DATE_QUERY_FORMAT } from '../../includes/date-utils';
import { EventTable } from '../event-table';
import productsContext from '../../blocks/products-context/context';
import calendarContext from '../../blocks/bookings-calendar/context/context';
import availabilityContext from '../../blocks/availability-context/context';
import commonContext from '../../blocks/common-context/context';

const EventCalendar = ( props ) => {
	const [ selectedDate, setSelectedDate ] = useState( moment().format( 'YYYY-MM-DD' ) );

	const {
		requestProducts,
		getProducts,
		products,
		productsRequesting,
		productsError,
	} = useContext( productsContext );

	const {
		dateRangeType,
	} = useContext( calendarContext );

	const {
		availabilityRequesting,
		getAvailability,
		requestAvailability,
		availabilityError,
		hasResultsForQuery,
	} = useContext( availabilityContext );

	const {
		resetOffset,
		offset,
	} = useContext( commonContext );

	const { isSmallViewport } = props;
	const startEndTimes = getCalStartEndTime( dateRangeType, offset );
	const { minDate, maxDate, currentMonth } = startEndTimes;
	const { minDate: queryMinDate, maxDate: queryMaxDate } = formatDateQueryLimits( startEndTimes.minDate, startEndTimes.maxDate );
	const isError = productsError || availabilityError;
	const isRequesting = productsRequesting || availabilityRequesting;

	const availabilityQuery = {
		productIds: props.productIds,
		categoryIds: props.categoryIds,
		resourceIds: props.resourceIds,
		minDate: queryMinDate,
		maxDate: queryMaxDate,
	};

	const productsQuery = {
		productIds: props.productIds,
	};

	useEffect( () => {
		resetOffset();
	}, [ dateRangeType ] );

	useEffect( () => {
		if( offset === 0 ) {
			setSelectedDate( moment().format( 'YYYY-MM-DD' ) );
		} else {
			setSelectedDate( moment().add( offset, 'months' ).startOf( 'month' ).format( 'YYYY-MM-DD' ) );
		}
	}, [ offset ] );

	useEffect( () => {
		if ( ! productsRequesting && getProducts().count === false ) {
			requestProducts( productsQuery );
		}

		if ( ! availabilityRequesting && ! hasResultsForQuery( availabilityQuery ) && ! isError ) {
			requestAvailability( availabilityQuery );
		}
	}, [ offset, isSmallViewport, dateRangeType, availabilityRequesting ] );

	const tryAgain = () => {
		requestAvailability( availabilityQuery );
	};

	const filterAvailability = memorize(
		( availability, _dateRangeType, _isSmallViewport ) => {
			if ( DATE_RANGES.THIS_WEEK === _dateRangeType && ! _isSmallViewport ) {
				return transformAvailabilityForWeekView( availability );
			}
			return transformAvailabilityForMonthView( availability );
		}
	);

	const availability = filterAvailability( getAvailability( availabilityQuery ), dateRangeType, isSmallViewport );

	/*
	* Globally set locale for moment.
	*/
	moment.locale( wc_bookings_availability_args.locale );

	const howManyWeeks = () => {
		return moment( maxDate ).startOf( 'day' ).diff( moment( minDate ).endOf( 'day' ), 'week' ) + 1;
	};

	const renderHeader = () => {
		const weekView = dateRangeType === DATE_RANGES.THIS_WEEK;

		if ( weekView && isSmallViewport ) {
			return '';
		}

		const shift = weekView ? 1 : 0;
		const wpStartOfWeek = parseInt( wc_bookings_availability_args.start_of_week, 10 );
		const header = [];
		let startOfWeek = wpStartOfWeek === 0 ? 7 : wpStartOfWeek;
		const date = moment( minDate );

		for ( let i = 1 + shift; i <= 7 + shift; i++ ) {
			const style = {
				gridRow: '1 / 1',
				gridColumn: `${ i } / ${ i }`,
			};
			const day = startOfWeek++;
			startOfWeek = startOfWeek === 8 ? 1 : startOfWeek;
			const dayNumber = weekView ? ( ' ' + date.format( 'DD' ) ) : '';
			date.add( 1, 'day' );
			const key = `day-of-week-${ i }`;
			header.push( <div className="wc-bookings-availability-calendar-header-item" key={ key } style={ style } >{ getDayOfWeek( day ) + dayNumber }</div> );
		}

		return header;
	};

	const renderCalendarMonthDays = () => {
		const { isPreview } = props;

		const calendarDays = [];
		let number = 0;

		let headerStyle = {};
		let dayStyle = {};

		// How many possible rows single calendar day has.
		const rowsPerDay = isSmallViewport ? 1 : 6;

		const start = moment( minDate );
		const end = moment( maxDate );

		while ( start.isSameOrBefore( end, 'day' ) ) {
			const column = ( number % 7 ) + 1;

			if ( ! isSmallViewport ) {
				const rowIndex = ~~( number / 7 ); // eslint-disable-line no-bitwise
				headerStyle = {
					gridColumn: `${ column } / ${ column }`,
					gridRow: `${ ( rowIndex * rowsPerDay ) + 2 } / span 1`,
				};
				dayStyle = {
					gridColumn: `${ column } / ${ column }`,
					gridRow: `${ ( rowIndex * rowsPerDay ) + 3 } / span ${ rowsPerDay - 1 }`,
				};
			}

			const date = moment( start ).format( 'YYYY-MM-DD' );
			const day = start.date();
			const dayString = ( day === 1 && ! isSmallViewport ? start.format( 'MMM' ) + ' ' : '' ) + day;

			const headerKey = `header-key-${ number }`;
			const dayKey = `day-key-${ number }`;

			const headerClass = classNames( 'wc-bookings-availability-cal-date', {
				'wc-bookings-availability-cal-date-other-month': ! moment( date ).isSame( currentMonth, 'month' ),
				'wc-bookings-availability-first-in-row': 1 === column,
				'wc-bookings-availability-selected-date': selectedDate === date,
				'wc-bookings-availability-has-no-items': ! availability[ date ] && ! isRequesting,
			} );

			if ( isSmallViewport ) {
				calendarDays.push(
					<span
						role="button"
						tabIndex="0"
						onKeyDown={ ( e ) => 'Enter' === e.key || ' ' === e.key ? setSelectedDate( date ) : '' }
						onClick={ () => setSelectedDate( date ) }
						key={ headerKey }
						style={ headerStyle }
						className={ headerClass }>
						{ dayString }
					</span> );
			} else {
				calendarDays.push( <span key={ headerKey }
					style={ headerStyle } className={ headerClass }>{ dayString }</span> );
			}

			const dayClassName = classNames( 'wc-bookings-availability-calendar-day', { 'wc-bookings-availability-first-in-row': 1 === column } );

			if ( ! isSmallViewport ) {
				let dayMarkup;

				if ( isRequesting ) {
					dayMarkup = (
						<div key={ dayKey } style={ dayStyle } className={ dayClassName }>
							<div className={ 'wc-bookings-availability-calendar-day-item wc-bookings-availability-calendar-day-item-placeholder' }>
								&nbsp;
							</div>
						</div>
					);
				} else {
					dayMarkup = (
						<div key={ dayKey } style={ dayStyle } className={ dayClassName }>
							{ productsWithAvailabilityOnDay( products, availability, date ).map( ( [ product, productAvailability ], index ) => (
								<EventCalendarItem
									isPreview={ isPreview }
									key={ index }
									availability={ productAvailability }
									isRequesting={ isRequesting }
									product={ product }
									date={ start.toDate() }
								/>
							) ) }
						</div>
					);
				}

				calendarDays.push( dayMarkup );
			}

			// Loop increments.
			number++;
			start.add( 1, 'day' );
		}

		return calendarDays;
	};

	const renderCalendarWeekDays = () => {
		const { isPreview } = props;

		// Add placeholder availability while requesting.
		const weekAvailability = ( ! isRequesting ) ? availability : { '00': null };

		const calendarItems = [];
		const format = wc_bookings_availability_args.time_format.replace( ':i', '' );
		for ( const hour in weekAvailability ) {
			const hourRow = parseInt( hour, 10 ) + 2;
			const hourStyle = {
				gridColumn: `${ 1 } / ${ 1 }`,
				gridRow: `${ hourRow } /  ${ hourRow }`,
			};
			const time = formatTime( format, moment().hour( hour ) );
			const hourKey = `hour-key-${ hour }`;
			// Hide hour label while requesting.
			const hourColumnMarkup = ( ! isRequesting ) ? (
				<span className="wc-bookings-availability-calendar-week-hour"style={ hourStyle } key={ hourKey } >{ time }</span>
			) : null;

			// Hours column.
			calendarItems.push( hourColumnMarkup );

			const start = moment( minDate );
			const end = moment( maxDate );
			let column = 1; // Column 1 is for hours.
			const days = weekAvailability[ hour ] || {};

			while ( start.isSameOrBefore( end, 'day' ) ) {
				const date = moment( start ).format( 'YYYY-MM-DD' );
				column++;

				const hourDateKey = `hour-day-key-${ hour }-${ date }`;
				const hourDateStyle = {
					gridColumn: `${ column } / ${ column }`,
					gridRow: `${ hourRow } /  ${ hourRow }`,
				};

				if ( isRequesting ) {
					// Loading placeholder.
					calendarItems.push(
						<div key={ hourDateKey } style={ hourDateStyle } className="wc-bookings-availability-calendar-day">
							<div className={ 'wc-bookings-availability-calendar-day-item wc-bookings-availability-calendar-day-item-placeholder' }>
								&nbsp;
							</div>
						</div>
					);
					// Advance to the next day.
					start.add( 1, 'day' );
					continue;
				}

				if ( ! ( date in days ) ) {
					// Nothing todo here. Use empty container ( for styling ) and go to the next day.
					calendarItems.push( <span key={ hourDateKey } style={ hourDateStyle } className="wc-bookings-availability-calendar-day" /> );
					// Advance to the next day.
					start.add( 1, 'day' );
					continue;
				}

				const slots = weekAvailability[ hour ][ date ];
				const loadMore = slots.length < weekAvailability[ hour ][ date ].length;

				const hourMarkup = (
					<div key={ hourDateKey } style={ hourDateStyle } className="wc-bookings-availability-calendar-day">
						{ productsWithAvailabilityOnDayHour( products, slots ).map( ( [ product, productAvailability ], index ) => (
							<EventCalendarItem
								isPreview={ isPreview }
								key={ index }
								availability={ productAvailability }
								isRequesting={ isRequesting }
								product={ product }
								date={ start.toDate() }
							/>
						) ) }
						{ loadMore && <div>{ 'Load more.' }</div> }
					</div>
				);

				calendarItems.push( hourMarkup );
				// Advance to the next day.
				start.add( 1, 'day' );
			}
		}
		return calendarItems;
	};

	const renderCalendarWeekDaysSmallViewport = () => {
		const start = moment( minDate );
		const end = moment( maxDate );

		const calendarDays = [];

		while ( start.isSameOrBefore( end, 'day' ) ) {
			const date = moment( start ).format( 'YYYY-MM-DD' );

			const day = start.date();
			const dayString = start.format( 'ddd' );

			const headerKey = `header-key-${ day }`;

			const headerClass = classNames( 'wc-bookings-availability-cal-date', {
				'wc-bookings-availability-selected-date': selectedDate === date,
				'wc-bookings-availability-has-no-items': ! availability[ date ] && ! isRequesting,
			} );

			calendarDays.push(
				<span
					role="button"
					tabIndex="0"
					onKeyDown={ ( e ) => 'Enter' === e.key || ' ' === e.key ? setSelectedDate( date ) : '' }
					onClick={ () => setSelectedDate( date ) }
					key={ headerKey }
					className={ headerClass }>
					{ dayString }<br />{ day }
				</span> );

			start.add( 1, 'day' );
		}
		return calendarDays;
	};

	const renderDays = () => {
		if ( DATE_RANGES.THIS_WEEK === dateRangeType ) {
			if ( isSmallViewport ) {
				return renderCalendarWeekDaysSmallViewport();
			}
			return renderCalendarWeekDays();
		}
		return renderCalendarMonthDays();
	};

	let calendarClass = classNames( 'wc-bookings-availability-calendar', {
		'wc-bookings-availability-calendar-week-view': DATE_RANGES.THIS_WEEK === dateRangeType,
		'wc-bookings-availability-calendar-small': isSmallViewport,
		'wc-bookings-availability-calendar-requesting': isRequesting,
	} );

	if ( DATE_RANGES.THIS_MONTH === dateRangeType ) {
		const numOfWeeks = howManyWeeks();
		calendarClass = classNames( calendarClass, {
			'wc-bookings-availability-calendar-4-rows-month': 4 === numOfWeeks,
			'wc-bookings-availability-calendar-5-rows-month': 5 === numOfWeeks,
			'wc-bookings-availability-calendar-6-rows-month': 6 === numOfWeeks,
		} );
	}

	const calendarContainerClass = classNames( 'wc-bookings-availability-calendar-container', {
		'wc-bookings-availability-calendar-container-week-view': DATE_RANGES.THIS_WEEK === dateRangeType,
		'wc-bookings-availability-calendar-container-month-view': DATE_RANGES.THIS_MONTH === dateRangeType,
		'wc-bookings-availability-calendar-container-small': isSmallViewport,
	} );

	return isError ?
		(
			<div className="woocommerce-error notice notice-error">
				{ __( 'We weren\'t able to load the store calendar.', 'woocommerce-bookings-availability' ) }
				{ ' ' }
				<a href="#" onClick={ tryAgain }>{ __( 'Try again', 'woocommerce-bookings-availability' ) }</a> { /* eslint-disable-line jsx-a11y/anchor-is-valid */ }
			</div>
		) : (
			<div>
				<div className={ calendarContainerClass }>
					<div className={ calendarClass }>
						{ renderHeader() }
						{ renderDays() }
						{ isRequesting ? <div className="wc-bookings-availability-calendar-container-loading-animation"><div></div></div> : '' }
					</div>
				</div>
				{
					isSmallViewport ? <EventTable
						minDate={ moment( selectedDate ).startOf( 'day' ).format( DATE_QUERY_FORMAT ) }
						maxDate={ moment( selectedDate ).endOf( 'day' ).format( DATE_QUERY_FORMAT ) }
						productIds={ props.productIds }
						categoryIds={ props.categoryIds }
						resourceIds={ props.resourceIds }
						remainingRecords={ 0 }
						infiniteScrollEnabled={ false }
						isLastPage={ true }
						showEmptyDates={ false }
						forceDateFromProps={ true }
					/> : ''
				}
			</div>
		);
};

EventCalendar.defaultProps = {
	availability: {},
	categoryIds: [],
	offset: 0,
	isSmallViewport: false,
};

EventCalendar.propTypes = {
	/**
	 * Sets up component for in-editor preview if true.
	 */
	isPreview: PropTypes.bool,
	/**
	 * Display sold out bookings.
	 */
	showSoldOut: PropTypes.bool,
	/**
	 * Whether the current device has a small screen or window.
	 */
	isSmallViewport: PropTypes.bool,

	offset: PropTypes.number,

	productIds: PropTypes.node,

	categoryIds: PropTypes.node,

	resourceIds: PropTypes.node,
};

const productsWithAvailabilityOnDay = function( products, availability, date ) {
	// Don't generate artifical entries.
	if ( ! ( date in availability ) ) {
		return [];
	}
	const dayAvailability = availability[ date ];
	return transform( products, ( result, product, productId ) => {
		const productAvailability = filter( dayAvailability, ( slot ) => {
			return slot.productId === parseInt( productId );
		} );
		if ( productAvailability.length > 0 ) {
			result.push( [ { id: productId, ...product }, productAvailability ] );
		}
		return result;
	}, [] );
};

const productsWithAvailabilityOnDayHour = function( products, availability ) {
	return transform( products, ( result, product, productId ) => {
		const productAvailability = filter( availability, ( slot ) => {
			return slot.productId === parseInt( productId );
		} );

		if ( productAvailability.length > 0 ) {
			result.push( [ { id: productId, ...product }, productAvailability ] );
		}

		return result;
	}, [] );
};

const transformAvailabilityForMonthView = ( availability ) => {
	const availabilityByDays = {};
	for ( const slot of availability ) {
		const key = moment( slot.date ).format( 'YYYY-MM-DD' );
		! ( key in availabilityByDays ) && ( availabilityByDays[ key ] = [] ); // eslint-disable-line no-unused-expressions
		availabilityByDays[ key ].push( slot );
	}

	return mapValues( availabilityByDays, ( day ) => (
		day.sort( ( a, b ) => moment( a.date ).diff( moment( b.date ) ) )
	) );
};

const transformAvailabilityForWeekView = ( availability ) => {
	/**
	 * Availability structure
	 * slot_hour{}:days{}:slots.
	 * slots are time sorted
	 */
	const availabilityByHour = {};

	for ( const slot of availability ) {
		const hour = moment( slot.date ).format( 'HH' );
		const day = moment( slot.date ).format( 'YYYY-MM-DD' );
		! ( hour in availabilityByHour ) && ( availabilityByHour[ hour ] = {} ); // eslint-disable-line no-unused-expressions
		! ( day in availabilityByHour[ hour ] ) && ( availabilityByHour[ hour ][ day ] = [] ); // eslint-disable-line no-unused-expressions
		availabilityByHour[ hour ][ day ].push( slot );
	}

	return availabilityByHour; // this should be sorted if REST sends in sorted order.
};

export default withViewportMatch( { isSmallViewport: '< small' } )( EventCalendar );

// export lower order component to expose it for unit testing.
export { EventCalendar };
