/**
 * External dependencies
 */
import { Fragment, useState, useContext, useEffect, useLayoutEffect, useRef } from '@wordpress/element';
import { PropTypes } from 'prop-types';
import { __ } from '@wordpress/i18n';
import moment from 'moment';

/**
 * Internal dependencies
 */
import EventItem from '../../components/event-item';
import EmptyEventItem from '../../components/event-item/empty';
import {
	getDateQueryLimits,
	isCustom,
	getNoSlotsInRangeMessage,
	getErrorLoadingSlotsInRangeMessage,
	getIsRestrictedDay,
} from '../../includes/date-utils';
import { DEFAULT_PAGE_SIZE } from '../../includes/misc-utils';
import PageLoader from '../../components/page-loader';
import productContext from '../../blocks/products-context/context';
import availabilityContext from '../../blocks/availability-context/context';
import scheduleContext from '../../blocks/bookings-schedule/context/context';
import calendarContext from '../../blocks/bookings-calendar/context/context';
import commonContext from '../../blocks/common-context/context';

const usePreviousProps = ( dateRangeType, infiniteScrollEnabled, offset, page, slotsPerPage ) => {
	const ref = useRef();

	useEffect( () => {
		ref.current = { dateRangeType, infiniteScrollEnabled, offset, page, slotsPerPage };
	}, [ dateRangeType, infiniteScrollEnabled, offset, page, slotsPerPage ] );

	return ref.current;
};

const EventTable = ( props ) => {
	const [ page, setPage ] = useState( 1 );
	const firstRender = useRef( true );
	const pageLoader = useRef( null );

	const checkIfNextPageShouldBeFetched = () => {
		if ( pageLoader.current === null ) {
			return false;
		}
		return pageLoader.current.scrollTriggerIsInViewport();
	};

	const {
		requestProducts,
		getProducts,
		products,
		productsRequesting,
		productsError,
	} = useContext( productContext );

	const {
		hasResultsForQuery,
		availabilityRequesting,
		getAvailability,
		requestAvailability,
		availabilityError,
		getRemainingRecordsCount,
		highestPageForQuery,
	} = useContext( availabilityContext );

	const {
		dateRangeType,
	} = useContext( scheduleContext );

	const {
		showSoldOut,
	} = useContext( calendarContext );

	const {
		resetOffset,
		offset,
	} = useContext( commonContext );

	let { minDate, maxDate, forceDateFromProps } = props;
	const { productIds, categoryIds, resourceIds, infiniteScrollEnabled, slotsPerPage, getPastTimes } = props;

	const previousProps = usePreviousProps( dateRangeType, infiniteScrollEnabled, offset, page, slotsPerPage );

	const productsQuery = {
		productIds,
	};

	forceDateFromProps = forceDateFromProps === undefined ? false : forceDateFromProps;
	const forcedDate = forceDateFromProps ? minDate : false;

	let currentDate = 1;

	if ( ! isCustom( dateRangeType ) && ! forceDateFromProps ) {
		if ( 0 === offset && 'this-month' === dateRangeType ) {
			const date = new Date();
			currentDate = date.getDate();
		}

		const dateRange = getDateQueryLimits( dateRangeType, offset, currentDate );
		minDate = dateRange.minDate;
		maxDate = dateRange.maxDate;
	}

	const availabilityQuery = {
		minDate,
		maxDate,
		productIds,
		categoryIds,
		resourceIds,
		page,
		getPastTimes: getPastTimes,
		pageSize: infiniteScrollEnabled ? DEFAULT_PAGE_SIZE : slotsPerPage,
		showSoldOut: showSoldOut,
	};

	const isError = productsError || availabilityError;
	const isRequesting = productsRequesting || availabilityRequesting;
	const remainingRecords = getRemainingRecordsCount( availabilityQuery );
	const hasResults = hasResultsForQuery( availabilityQuery );
	const isLastPage = hasResults && ( remainingRecords === 0 );

	// First run, get initial data.
	useLayoutEffect( () => {
		if ( ! productsRequesting && getProducts().count === false ) {
			requestProducts( productsQuery );
		}
		requestAvailability( availabilityQuery );
	}, [] );

	// Detect a change that requries a new data fetch.
	useLayoutEffect( () => {
		if ( firstRender.current ) {
			return;
		}

		if ( dateRangeType !== previousProps.dateRangeType ) {
			if ( offset !== 0 ) {
				resetOffset();
				return;
			}
			const newPage = highestPageForQuery( availabilityQuery );
			availabilityQuery.page = newPage;
			setPage( newPage );
		}

		if ( ( slotsPerPage !== previousProps.slotsPerPage ) ||
			( infiniteScrollEnabled === false && previousProps.infiniteScrollEnabled === true ) ||
			( offset !== previousProps.offset ) ) {
			const newPage = highestPageForQuery( availabilityQuery );
			availabilityQuery.page = newPage;
			setPage( newPage );
		}

		if ( infiniteScrollEnabled === true && previousProps.infiniteScrollEnabled === false ) {
			const shouldFetch = checkIfNextPageShouldBeFetched();

			if ( shouldFetch ) {
				const newPage = highestPageForQuery( availabilityQuery ) + 1;
				availabilityQuery.page = newPage;
				setPage( newPage );
			}
		}

		if ( ! availabilityRequesting && ! hasResults && ! isLastPage && ! isError ) {
			requestAvailability( availabilityQuery );
		}
	}, [ dateRangeType, infiniteScrollEnabled, offset, page, slotsPerPage, availabilityRequesting, forcedDate ] );

	// Detect first render.
	useEffect( () => {
		firstRender.current = false;
	}, [] );

	const availability = getAvailability( availabilityQuery );

	const loadMoreSlots = () => {
		/*
		 * availabilityRequesting checks if we are not currently requesting.
		 * We don't want to bump page in that case.
		 * hasResultsForQuery is true only if we have results for previous pages.
		 * This check prevents skipping over a page. If page was increased by accident
		 * twice without request being made we will face a inf loop.
		 */

		if ( availabilityRequesting || ! hasResultsForQuery( availabilityQuery ) ) {
			return;
		}
		setPage( page + 1 );
	};

	const tryAgain = () => {
		requestAvailability( availabilityQuery );
	};

	const renderEvents = () => {
		const {
			isPreview,
			showEmptyDates,
		} = props;

		let previousDate = null;
		let shownAvailability = availability;
		const placeholderAvailability = availability.slice( 0 );
		const eventItems = [];
		const placeHolderCount = ( infiniteScrollEnabled || 0 === remainingRecords ) ? DEFAULT_PAGE_SIZE : Math.min( slotsPerPage, remainingRecords );
		const placeHolderProduct = {
			name: 'placeholder',
			duration: 1,
			duration_unit: 'day',
			placeholder: true,
		};
		if ( isRequesting ) {
			for ( let i = 0; i < placeHolderCount; i++ ) {
				placeholderAvailability.push( {
					title: 'placeholder',
					date: new Date( minDate ),
					cost: 0.00,
					productId: 0,
					available: 0,
					placeholder: true,
				} );
			}
			shownAvailability = placeholderAvailability;
		}

		let key = 0;
		shownAvailability.forEach( ( data ) => {
			const { date } = data;
			const product = products[ data.productId ] || placeHolderProduct;
			const momentPreviousDate = moment( previousDate );
			let addSubHeader = false;
			if ( ! previousDate || momentPreviousDate.isBefore( date, 'day' ) ) {
				momentPreviousDate.add( 1, 'day' );

				while ( momentPreviousDate.isBefore( date, 'day' ) ) {
					if ( showEmptyDates ) {
						eventItems.push( <EmptyEventItem key={ key } date={ momentPreviousDate.toDate() } /> );
						key++;
					}
					momentPreviousDate.add( 1, 'day' );
				}
				addSubHeader = true;
			}

			previousDate = date;
			if ( ! getIsRestrictedDay( date, product ) ) {
				eventItems.push(
					<EventItem isPreview={ isPreview } key={ key } data={ data } product={ product } addSubheader={ addSubHeader } />
				);
			}
			key++;
		} );

		const addEndPaddingItems = ( ! ( page === 1 && isRequesting ) ) && remainingRecords === 0;

		if ( addEndPaddingItems && moment( previousDate ).isBefore( maxDate, 'day' ) ) {
			const momentPreviousDate = moment( previousDate );
			while ( momentPreviousDate.isBefore( maxDate, 'day' ) ) {
				momentPreviousDate.add( 1, 'day' );
				if ( showEmptyDates ) {
					eventItems.push( <EmptyEventItem key={ key } date={ momentPreviousDate.toDate() } /> );
					key++;
				}
			}
		}

		return (
			shownAvailability.length ? (
				<Fragment>
					<ul className="wc-bookings-availability-event-table">
						{ eventItems }
					</ul>
					{ ! isLastPage &&
						<PageLoader ref={ pageLoader } infiniteScrollEnabled={ infiniteScrollEnabled } remainingRecords={ remainingRecords } loadMore={ loadMoreSlots } isLoading={ availabilityRequesting } />
					}
				</Fragment>
			) : (
				<div className="woocommerce-error notice notice-empty">
					{ getNoSlotsInRangeMessage( dateRangeType ) }
				</div>
			)
		);
	};

	return isError ?
		(
			<div className="woocommerce-error notice notice-error">
				{ getErrorLoadingSlotsInRangeMessage( dateRangeType ) }
				{ ' ' }
				<a href="#" onClick={ tryAgain }>{ __( 'Try again', 'woocommerce-bookings-availability' ) }</a>{ /* eslint-disable-line jsx-a11y/anchor-is-valid */ }
			</div>
		) : (
			renderEvents()
		);
};

EventTable.defaultProps = {
	productIds: [],
	categoryIds: [],
	resourceIds: [],
	offset: 0,
	getPastTimes: false,
};

EventTable.propTypes = {
	/**
	 * Sets up component for in-editor preview if true.
	 */
	isPreview: PropTypes.bool,
	/**
	 * If we should automattically load the next page on scroll.
	 */
	infiniteScrollEnabled: PropTypes.bool.isRequired,
	/**
	 * Page size.
	 */
	slotsPerPage: PropTypes.number,

	showEmptyDates: PropTypes.bool,

	minDate: PropTypes.string,

	maxDate: PropTypes.string,

	forceDateFromProps: PropTypes.bool,

	offset: PropTypes.number,

	productIds: PropTypes.node,

	categoryIds: PropTypes.node,

	resourceIds: PropTypes.node,

	getPastTimes: PropTypes.bool,
};

export default EventTable;

// export lower order component to expose it for unit testing.
export { EventTable };
