/**
 * External dependencies
 */
import { __ } from '@wordpress/i18n';
import { useState } from '@wordpress/element';
import { PropTypes } from 'prop-types';
import apiFetch from '@wordpress/api-fetch';

/**
 * Internal dependencies
 */
import { formatDuration, getHumanReadableDate } from '../../includes/date-utils';

const EventItem = ( props ) => {
	const [ available, setAvailable ] = useState( true );
	const [ loading, setLoading ] = useState( false );

	const onClickBook = () => {
		const { serverDate, productId, available: availableData } = props.data;
		const { product, product: { permalink } } = props;
		const isAvailable = availableData && available;
		const isBookable = isAvailable && ( ! ( product.has_persons || product.has_resources || 'customer' === product.duration_type ) );

		setLoading( true );

		if ( ! isBookable ) {
			window.location.href = permalink;
			return;
		}

		apiFetch( {
			url: wc_bookings_availability_args.ajax_url + '=wc_bookings_add_booking_to_cart' + '&product_id=' + productId + '&date=' + encodeURIComponent( serverDate ) + '&security=' + wc_bookings_availability_args.nonces.add_booking_to_cart,
		} )
			.then( ( data ) => {
				if ( data.booked ) {
					setAvailable( false );
					setLoading( false );

					window.location.href = wc_bookings_availability_args.checkout_url;
				} else {
					window.location.href = data.link;
				}
			} );
	};

	const { isPreview, addSubheader, product } = props;
	const { name } = product;
	const { date, placeholder, available: availableData } = props.data;
	const priceHtml = product.price_html;
	const isAvailable = availableData && available;
	let listClassName = 'wc-bookings-availability-item';

	if ( placeholder ) {
		listClassName += ' wc-bookings-availability-item-is-placeholder';
	}

	let buttonText = ( product.has_persons || product.has_resources || 'customer' === product.duration_type ) ? __( 'Select options', 'woocommerce-bookings-availability' ) : __( 'Book now', 'woocommerce-bookings-availability' );

	let buttonClassName = 'wp-block-button__link wc-bookings-availability-book-button';

	if ( ! isAvailable ) {
		buttonText = __( 'Sold out', 'woocommerce-bookings-availability' );
		buttonClassName += ' wc-bookings-availability-item-sold-out';
	}

	return (
		<li className={ listClassName } >
			{ addSubheader &&
				<div className="wc-bookings-availability-item-subheader">
					<span>{ getHumanReadableDate( date ) }</span>
				</div>
			}
			<div className="wc-bookings-availability-item-body">
				<div className="wc-bookings-availability-item-information" >
					<div>
						<h3>
							<a href={ product.permalink }>{ name }</a>
						</h3>
						<div><span>{ formatDuration( product, date ) }</span></div>
						<div className="wc-bookings-availability-item-price" dangerouslySetInnerHTML={ { __html: priceHtml } } />
					</div>
				</div>
				<div className="wp-block-button">
					{ ( isPreview || ! available ) ? (
						<span className={ buttonClassName }>
							{ buttonText }
						</span>
					) : (
						<button onClick={ onClickBook } disabled={ loading } className={ buttonClassName }>
							{ buttonText }
						</button>
					) }
				</div>
			</div>
		</li>
	);
};

EventItem.propTypes = {
	/**
	 * Availability data from wc_bookings_find_booking_slots ajax endpoint.
	 */
	data: PropTypes.shape( {
		booked: PropTypes.number,
		available: PropTypes.number,
		resources: PropTypes.object,
		productId: PropTypes.number,
		title: PropTypes.string,
		date: PropTypes.date,
		serverDate: PropTypes.string,
		placeholder: PropTypes.bool,
		duration: PropTypes.number,
		durationUnit: PropTypes.string,
	} ).isRequired,
	/**
	 * Sets up component for in-editor preview if true.
	 */
	isPreview: PropTypes.bool,
	addSubheader: PropTypes.bool,
	product: PropTypes.object.isRequired,
};

EventItem.defaultProps = {
	addSubheader: false,
};

export default EventItem;
